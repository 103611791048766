






















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { AuthService, CompanyService, ProfileService } from '@/lib/services';
import { Company } from '@/models';
import { unwrapError } from '@/lib/helpers';

@Component({})
export default class CompanySwitchPage extends Vue {
  companies: Company[] = [];
  error: string = '';

  async created(): Promise<void> {
    this.companies = await CompanyService.all();
    this.companies = await Promise.all(
      this.companies.map((e) => this.fetchLogo(e)),
    );
  }

  async fetchLogo(company: Company): Promise<Company> {
    try {
      if (company.logo) {
        company.renderedLogo = await ProfileService.getLogo(
          company.logo as string,
        );
      }
    } catch (e) {
      // ignore the company with no logo. SAD!
    }
    return company;
  }

  async login(companyId: number): Promise<void> {
    try {
      await AuthService.switchCompany(companyId);
      await this.$router.push('/');
    } catch (e) {
      this.error = unwrapError(e);
    }
  }
}
